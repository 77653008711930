/**
 * Track step function for google analytics.
 * */
var trackStep = function trackStep(serviceFormID,stepNr,subStepNr) {

    if(subStepNr == ''){
        subStepNr = 0;
    }

    //var currentPath = window.location.pathname;
    var currentPath = '/form/' + serviceFormID;


    var virtualPathWithStep = currentPath;

    if(stepNr != null){
        virtualPathWithStep = virtualPathWithStep + '/' + stepNr;
        if(subStepNr != null){
            virtualPathWithStep = virtualPathWithStep + '/' + subStepNr;
        }
    }
    //console.log(virtualPathWithStep);
    _gaq.push(['_setAccount', 'UA-684167-22']);
    _gaq.push(['_trackPageview', virtualPathWithStep]);

};


/**
 * Export Public API
 */
module.exports.trackStep = trackStep;