var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;


// Extra dependencys added in januaru 2019

// -----------------------------------------
// Show LC Loader and hide what's needed
// -----------------------------------------
window.showLCLoader = function ($show, $hide) {
    if ($show != null) $show.addClass('show-loader');
    if ($hide != null) $hide.addClass('hide-loader');
}

// Validator get error messages
function getFormMsg(selector) {
    return $(selector).attr('data-msg');
}


if ($('.lc-booking-slider-container').length) {

    // -----------------------------------------
    // Jquery Validation Slider Pri
    // -----------------------------------------
    window.validatorFormPriSlider = function() {
        $('.lc-booking-form-pri-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code : {
                    required:true, digits:true, maxlength:5, minlength:5,
                    remote: {
                        url: globalUrl + 'tw/pri/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required:true, digits:true, maxlength:4, minlength:1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function(error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html':true,'trigger':'hover focus'});
                }
            }
        });
    }


    // -----------------------------------------
    // Jquery Validation Slider fst
    // -----------------------------------------
    window.validatorFormFstSlider = function() {
        $('.lc-booking-form-fst-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code : {
                    required:true, digits:true, maxlength:5, minlength:5,
                    remote: {
                        url: globalUrl + 'tw/fst/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required:true, digits:true, maxlength:4, minlength:1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function(error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html':true,'trigger':'hover focus'});
                }
            }
        });
    }

    // -----------------------------------------
    // Jquery Validation Slider ftgquanity
    // -----------------------------------------
    window.validatorFormFtgSlider = function() {
        $('.lc-booking-form-ftg-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code : {
                    required:true, digits:true, maxlength:5, minlength:5,
                    remote: {
                        url: globalUrl + 'tw/ftg/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required:true, digits:true, maxlength:4, minlength:1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function(error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html':true,'trigger':'hover focus'});
                }
            }
        });
    }

}


// Variable to init a custom service from pagevars
var initServiceId = '';


if ($('.lc-booking-form-pri-slider').length > 0) {

    // Try to get custom init-service from page vars.
    if ( $( "#init-service-id" ).length ) {
        initServiceId = $('#init-service-id').text();
    }
    if(initServiceId != ''){
        var loadURL = globalUrl + '/tw/pri/forms/' + initServiceId + '/slider-form.cfm';
    } else {
        var loadURL = globalUrl + '/tw/pri/forms/hemstadning/slider-form.cfm';
    }

    $('.lc-booking-form-pri-slider').load(loadURL);
}

if ($('.lc-booking-form-fst-slider').length > 0) {

    // Try to get custom init-service from page vars.
    if ( $( "#init-service-id" ).length ) {
        initServiceId = $('#init-service-id').text();
    }
    if(initServiceId != ''){
      var loadURL = globalUrl + '/tw/fst/forms/' + initServiceId + '/slider-form.cfm';
    } else {
      var loadURL = globalUrl + '/tw/fst/forms/trappstadning/slider-form.cfm';
    }
    $('.lc-booking-form-fst-slider').load(loadURL);
}

if ($('.lc-booking-form-ftg-slider').length > 0) {

    // Try to get custom init-service from page vars.
    if ( $( "#init-service-id" ).length ) {
        initServiceId = $('#init-service-id').text();
    }
    if(initServiceId != ''){
        var loadURL = globalUrl + '/tw/ftg/forms/' + initServiceId + '/slider-form.cfm';
    } else {
        var loadURL = globalUrl + '/tw/ftg/forms/kontorsstadning/slider-form.cfm';
    }

	$('.lc-booking-form-ftg-slider').load(loadURL);
} 
